<template>
  <Title :text="t(`t1`)" />
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="require(`@/assets/cover/qalc.jpg`)"
      :src-pic-mobile="require(`@/assets/cover/m/qalc.jpg`)"
    />
  </div>

  <div class="grid-column">
    <Share :title="t(`t1`)" />
  </div>
  <div class="grid-column grid-column_span_4">
    <div class="h2-1" v-html="t(`t2`)"></div>
  </div>

  <div class="grid-column">
    <div v-if="isMobile" class="grid-line"></div>
    <h3 class="h3 upper" v-html="t(`t3`)"></h3>
  </div>
  <div class="grid-column grid-column_span_3">
    <div v-if="isMobile" class="pm-18"></div>
    <div class="content-textarea" v-html="t(`t4`)"></div>
    <div v-if="isMobile" class="pm-18"></div>
  </div>
  <div class="grid-column"></div>

  <div class="grid-column grid-column_mobile_white">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_left"
    >
      <div class="grid-inside">
        <div>
          <div v-if="isMobile" class="pm-18"></div>
          <p>{{ t(`t9`) }}</p>
          <div v-if="isMobile" class="pm-10"></div>
          <div class="button-group2">
            <Button
              class="btn-grey"
              to="https://www.instagram.com/studyarabicru/?igshid=96crythtrzux"
              target="_blank"
              msg="IG"
            />
            <Button
              class="btn-grey"
              to="https://www.facebook.com/studyarabicru/"
              target="_blank"
              msg="FB"
            />
            <Button
              class="btn-grey"
              to="https://vk.com/studyarabicru"
              target="_blank"
              msg="VK"
            />
          </div>
        </div>
        <div v-if="isMobile" class="pm-18"></div>
        <Button
          class="btn-grey"
          to="https://studyarabic.ru/"
          target="_blank"
          :msg="t(`common_website`)"
        />
        <div v-if="isMobile" class="pm-18"></div>
      </div>
    </div>
  </div>
  <div class="grid-column grid-column_mobile_white grid-column_span_2">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top"
    >
      <div v-if="isMobile" class="pm-18"></div>
      <div class="h3" v-html="t(`t5`)"></div>
      <div v-if="isMobile" class="pm-24"></div>
      <div v-html="t(`t6`)"></div>
    </div>
  </div>
  <div class="grid-column grid-column_mobile_white grid-column_span_2">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_right"
    >
      <div v-if="isMobile" class="pm-10"></div>
      <div v-if="isMobile" class="grid-line"></div>
      <div v-if="isMobile" class="pm-10"></div>
      <div class="h3" v-html="t(`t7`)"></div>
      <div v-if="isMobile" class="pm-24"></div>
      <div v-html="t(`t8`)"></div>
      <div v-if="isMobile" class="pm-18"></div>
    </div>
  </div>
  <div class="grid-column grid-column_mobile_white grid-column_index_up">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_left grid-column__inner_position_bottom"
    ></div>
  </div>
  <div class="grid-column grid-column_mobile_white grid-column_index_up">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    ></div>
  </div>
  <div class="grid-column grid-column_mobile_white grid-column_index_up">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    ></div>
  </div>
  <div class="grid-column grid-column_mobile_white grid-column_index_up">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    ></div>
  </div>
  <div class="grid-column grid-column_mobile_white grid-column_index_up">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom grid-column__inner_position_right"
    ></div>
  </div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "QALC",
  components: {
    Title,
    Promo,
    Share,
    News,
    Button,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t };
  },
});
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "t1": "QATAR ARABIC LANGUAGE CENTER",
    "t2": "<p>QALC is a language school in Moscow, Russia. This is a place where you can learn Arabic and be completely immersed in Arab culture. It is access to good quality tuition headed by experienced teachers, taking advantage of the latest techniques and the most effective curriculum.</p><p>Our mission is much broader than merely language learning – we want to create a community of people interested in Qatar and Arabic culture, its traditions, foundations and bases, business and educational opportunities.</p>",
    "t3": "WHY THE ARABIC LANGUAGE?",
    "t4": "<p>Arabic is spoken by around 422 million speakers (native and non-native) in the Arab world as well as in the Arab diaspora, making it one of the five most spoken languages in the world. It is the official language of many international organisations, such as the UN, the Arab League and the African Union.</p><p>Arabic has many different dialects, each has its own unique flavour and style. Standard Arabic is formal Arabic spoken all over the world.</p><p>Middle Eastern economies are growing rapidly, creating many educational and career opportunities. Proficiency in Arabic deepens your insight into business culture in the Middle East and equips you to make strong, key personal business relations.</p><p>Language immersion is a great and exciting experience. In the Middle East, there is no such thing as a boring day. The Arabs are very hospitable and friendly. If you go deeply into the culture and traditions of an Arab country, you will be on your way to learn more about the world.</p>",
    "t5": "OUR PROGRAMS",
    "t6": "<ol class='list'><li>Group language courses for beginners, intermediate and advanced students</li><li>Individual tuition</li><li>Special corporate courses</li><li>Speciality Arabic (business, economics, political studies)</li></ol>",
    "t7": "COMMUNITY EVENTS",
    "t8": "<ol class='list'><li>Discussion clubs</li><li>Open lectures and public events</li><li>Travel and culture experience</li><li>Competitions</li></ol>",    
    "t9": "Follow us:",
  },
  "ru": {
    "t1": "Катарский центр арабского языка (QALC)",
    "t2": "<p>QALC – языковая школа в Москве. В нашем центре вы сможете изучить арабский язык и глубоко погрузиться в культуру арабских стран. QALC дает возможность получить качественные знания под руководством опытных преподавателей с использованием передовых методик обучения и наиболее эффективной учебной программы.</p><p>Наша миссия намного шире, чем просто преподавание языка — мы хотим создать сообщество людей, интересующееся Катаром и арабской культурой, его традициями, основами, возможностями для развития бизнеса и образования.</p>",
    "t3": "ПОЧЕМУ АРАБСКИЙ ЯЗЫК?",
    "t4": "<p>Число говорящих на арабском языке составляет около 422 миллионов (в качестве родного и второго) в арабских странах, а также в арабских диаспорах, что делает его одним из пяти самых распространенных языков в мире. Арабский является официальным языком многих международных организаций, таких как ООН, Лига арабских государств и Африканский союз.</p><p>В арабском языке много разных диалектов, у каждого свой неповторимый колорит и стиль. Стандартный арабский — это формальный арабский язык, на котором говорят во всем мире.</p><p>Экономика стран Ближнего Востока развивается быстрыми темпами, что создает множество возможностей для получения образования и карьерного роста. Знание арабского языка углубит ваше понимание деловой культуры на Ближнем Востоке и даст возможность установить прочные деловые и личные отношения.</p><p>Погружение в языковую среду принесет яркие и увлекательные впечатления. На Ближнем Востоке не бывает скучных дней. Арабы очень гостеприимны и дружелюбны. Погружение в культуру и традиции арабской страны даст вам более широкую картину мира.</p>",
    "t5": "НАШИ ПРОГРАММЫ",
    "t6": "<ol class='list'><li>Групповые курсы Beginner</li><li>Групповые курсы Pre-intermediate</li><li>Групповые курсы Intermediate</li><li>Групповые курсы Upper-intermediate</li><li>Индивидуальные курсы</li><li>Корпоративные курсы</li><li>Профессионально-ориентированные курсы арабского языка (экономика, политология и т. д.)</li></ol>",
    "t7": "НАШИ МЕРОПРИЯТИЯ",
    "t8": "<ol class='list'><li>Дискуссионные клубы</li><li>Совместные проекты</li><li>Открытые лекции и массовые мероприятия</li><li>Поездки и культурные мероприятия</li><li>Конкурсы</li></ol>",
    "t9": "Мы в социальных сетях:",
  },
  "ar": {
    "t1": "المركز القطري للغة العربية",
    "t2": "<p>يعتبر المركز القطري للغة العربية مدرسة لغة في موسكو. في مركزنا يمكنك تعلم اللغة العربية والغوص بعمق في ثقافة الدول العربية. يوفر المركز القطري للغة العربية فرصة لاكتساب معرفة جيدة تحت إشراف المعلمين ذوي الخبرة باستخدام أفضل طرق التدريس والمناهج الدراسية الأكثر فاعلية.</p><p>مهمتنا أوسع بكثير من مجرد تدريس اللغة. نريد إنشاء مجتمع من الأشخاص المهتمين بقطر والثقافة العربية وتقاليدها وأسسها وفرصها لتطوير الأعمال والتعليم.</p>",
    "t3": "لماذا اللغة العربية؟",
    "t4": "<p>يبلغ عدد الناطقين باللغة العربية حوالي 422 مليون (كلغة أم أو اللغة الثانية) في الدول العربية وكذلك في الجاليات العربي، مما يجعلها واحدة من أكثر خمس لغات تحدثًا في العالم. اللغة العربية هي اللغة الرسمية للعديد من المنظمات الدولية مثل الأمم المتحدة وجامعة الدول العربية والاتحاد الأفريقي.</p><p>هناك العديد من اللهجات المختلفة في اللغة العربية ، ولكل منها نكهة وأسلوب فريد من نوعه. اللغة العربية الفصحى هي اللغة العربية الرسمية المستخدمة في جميع أنحاء العالم.</p><p>تتطور اقتصادات الشرق الأوسط بوتيرة سريعة، مما يخلق العديد من الفرص للتعليم والتطوير الوظيفي. ستعمل إتقان اللغة العربية على تعميق فهمك لثقافة الأعمال في الشرق الأوسط وتوفير فرصة لبناء علاقات تجارية وشخصية قوية.</p><p>سيجلب الغوص في بيئة اللغة تجربة حية ومثيرة. لا توجد أيام مملة في الشرق الأوسط. العرب مضيافون وودودون للغاية. يمنحك الغوص في ثقافة وتقاليد بلد عربي صورة أوسع للعالم.</p>",
    "t5": "برامجنا",
    "t6": "<ol class='list'><li>مجموعة دورات للمبتدئين</li><li>دورات المجموعة قبل المتوسط</li><li>دورات جماعية متوسط</li><li>دورات للمجموعة فوق المتوسط</li><li>دورات فردية</li><li>دورات للشركات</li><li>دورات لغة عربية ذات توجه مهني (الاقتصاد ، العلوم السياسية ، إلخ)</li></ol>",
    "t7": "فعالياتنا",
    "t8": "<ol class='list'><li>نوادي المناقشة</li><li>مشاريع مشتركة</li><li>محاضرات ومناسبات عامة مفتوحة</li><li>السفر والأنشطة الثقافية</li><li>مسابقات</li></ol>",
    "t9": "تجدوننا على مواقع التواصل الاجتماعي",
  }
}
</i18n>
